/*
Author:      Zachary Thomas
Created:     5/18/2022
Modified:    5/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.toggleVisible {
  position: relative;
  float: right;
  margin-right: 10px;
  margin-top: -36px;
  z-index: var(--toggle-visible-z-index);
  font-size: 1.2rem;
  cursor: pointer;
}
