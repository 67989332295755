/*
Author:      Zachary Thomas
Created:     11/8/2022
Modified:    11/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.header {
  position: sticky;
  top: -5px;
  padding: 2rem;
  background-color: var(--primary-background);
  color: var(--title);
}

.body {
  background-color: var(--background-close);

  td {
    padding: 1rem;
  }
}
