/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    7/20/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.body {
  margin: 0 auto;
  text-align: center;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 50%;
  }
}
