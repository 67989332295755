/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    11/18/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.text-blurb {
  text-align: center;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 50%;
  }
}
