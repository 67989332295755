/*
Author:      Zachary Thomas
Created:     5/20/2022
Modified:    5/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.body {
  display: flex;
  min-height: 80vh;
}
