/*
Author:      Zachary Thomas
Created:     11/18/2022
Modified:    11/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.body {
  background-color: var(--primary-background);
  z-index: var(--navbar-z-index);

  .title {
    color: var(--title);
    font-size: 2rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
  }
}
