/*
Author:      Zachary Thomas
Created:     11/8/2021
Modified:    11/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.body {
  background-color: var(--primary-background);
  color: var(--title);
  z-index: var(--footer-z-index);

  .copyright {
    font-size: 0.75rem;
    text-align: left;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
      text-align: right;
    }
  }
}
