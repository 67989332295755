/*
Author:      Zachary Thomas
Created:     11/23/2022
Modified:    11/23/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.header {
  position: sticky;
  top: -5px;
  color: var(--title);
  background-color: var(--primary-background);

  th {
    padding: 1rem;
  }
}

.body {
  background-color: var(--background-close);

  td {
    padding: 1rem;
  }
}

.error {
  text-align: center;
  padding: 2rem 0;
}
