/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    11/18/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.item {
  display: inline-block;

  .link {
    display: none;

    @include media-breakpoint-up(xl) {
      text-decoration: none;
      color: var(--navbar-link-inactive);
      font-size: 1rem;
      display: inline;
      padding: 0.5rem 0 0.5rem 0;
    }
  }

  .link:visited {
    color: var(--navbar-link-inactive);
  }

  .link:hover {
    color: var(--navbar-link-hover);
  }
}

.item.active .link {
  color: var(--navbar-link-active);
  text-decoration: underline;
}
