/*
Author:      Zachary Thomas
Created:     11/17/2022
Modified:    11/17/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.body {
  margin: 0 1.5rem 2rem 0;
  text-align: center;
  width: 100%;
  background-color: var(--primary-background);
  color: var(--title);

  .title {
    border-radius: 0;
    padding: 0;
    margin: 0;

    .header {
      border-radius: 0 !important;
      color: var(--title);
      background-color: var(--primary-background);
      margin: 0;
      border-radius: 0;
    }

    .header::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }

  .border {
    border-style: solid;
    border-width: 2px;
    border-color: var(--border);
    bottom: 1rem;
  }
}
