/*
Author:      Zachary Thomas
Created:     6/9/2021
Modified:    6/9/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.alert {
  font-size: 0.8rem !important;

  @include media-breakpoint-up(sm) {
    font-size: 1rem !important;
  }
}
