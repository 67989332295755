/*
Author:      Zachary Thomas
Created:     11/18/2022
Modified:    11/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.scrollSection {
  max-height: 85vh;
  overflow-y: auto;
  margin: 0 0 1rem 0;
}
