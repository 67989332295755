/*
Author:      Zachary Thomas
Created:     11/23/2022
Modified:    11/23/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.body {
  background-color: var(--background-close);
  border-color: var(--border);
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
}
