/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    10/8/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.spinnerContainer {
  visibility: visible;
  position: fixed;
  margin-left: -95px;
  margin-bottom: 150px;
  left: 50%;
  bottom: 50%;
  width: 0;
  height: 0;
  z-index: var(--spinner-z-index);

  .spinner {
    width: 12rem;
    height: 12rem;
    animation-duration: 0.6s;
  }
}
