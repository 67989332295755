/*
Author:      Zachary Thomas
Created:     6/8/2021
Modified:    5/20/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.body {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 75%;
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 25%;
    }
  }
}
