/*
Author:      Zachary Thomas
Created:     11/8/2022
Modified:    11/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

body {
  --primary-background: #2d545e;
  --title: #fff;
  --text: #000;
  --navbar-link-active: #fff;
  --navbar-link-inactive: #ffffffaa;
  --navbar-link-hover: #ffffffdd;
  --background-far: #f0f3f4;
  --background-mid: #f7f9f9;
  --background-close: #fff;
  --row-selected: #258591;
  --row-selected-text: #fff;
  --row-hover: #e4f6f8;
  --link-background-hover: #f8f0fc;
  --border: #d3d3d3;

  --navbar-z-index: 10005;
  --footer-z-index: 10010;
  --spinner-z-index: 19999;
}

html,
body {
  height: 100% !important;
  background-color: var(--background-far) !important;
  background-color: #eff1f3 !important;
}

html,
body {
  min-height: 100vh !important;
  background-color: var(--primary-background) !important;
}

main {
  flex: 1 0 auto;
  background-color: var(--background-far);
  min-height: 85vh;
}

.font-weight-bold {
  font-weight: 600;
}

.font-italic {
  font-style: italic;
}

.form-check-big {
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
}

.btn-light:hover {
  background-color: var(--light-button-hover) !important;
  border-color: var(--light-button-hover) !important;
}
