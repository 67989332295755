/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    3/30/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.text {
  font-size: 0.8rem;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
}
